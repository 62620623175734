"use client";
import { useAuth } from "~/providers/auth2";
import InsuranceApplicationIllustrationDialog from "../components/illustration-dialog";
import { useRouter } from "next/navigation";
import { api, RouterOutputs } from "~/trpc/react";
import { ApplicationPicker } from "~/components/application-picker";
import { useMemo, useState } from "react";
import InsuranceApplicationDialog from "~/components/dialog";
import { skipToken } from "@tanstack/react-query";
import { toast } from "@trident/ui/toast";
import Image from "next/image";
import WelcomeLaptop from "../../public/images/welcome-laptop.svg";
import { buttonVariants } from "@trident/ui/button";
import Link from "next/link";

type Policy = RouterOutputs["form"]["accessibleApps"][number];

export default function Landing() {
  const [selectedApplication, setSelectedApplication] = useState<
    Policy | undefined
  >(undefined);

  const router = useRouter();
  const { ready, type, session } = useAuth();
  const { isPending } = api.form.accessibleApps.useQuery(undefined, {
    enabled: !!ready && !!session && type === "user",
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const statuses = api.form.statuses.useQuery(
    !!selectedApplication
      ? { applicationID: selectedApplication.applicationID }
      : skipToken,
    {
      enabled: !!selectedApplication,
    },
  );

  const completed = useMemo(() => {
    if (!statuses.data) return false;
    return Object.values(statuses.data).every(
      (status) => status === "complete",
    );
  }, [statuses.data]);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-2">
      {session ? (
        <InsuranceApplicationIllustrationDialog
          title="Select Application to Continue"
          subtitle="You are signed in"
          info={{
            prompt: "Need help",
            content:
              "If you need support at any point during the application process contact agent@anchorwatch.com",
          }}
          buttonCallback={() => {
            const flowType = selectedApplication?.type;
            const applicationID = selectedApplication?.applicationID;
            const status = selectedApplication?.status;

            if (!flowType || !applicationID || !status) {
              toast(
                <div>
                  Missing application information. Please select a application
                  and try again.
                </div>,
              );
              return;
            }

            switch (flowType) {
              case "personal":
              case "enterprise": {
                if (status === "incomplete") {
                  router.push(
                    `/insurance-application/${flowType}/${selectedApplication.started ? "start" : "welcome"}/${applicationID}`,
                  );
                } else {
                  router.push(
                    `/insurance-application/status?applicationID=${applicationID}`,
                  );
                }
                break;
              }

              case "stakeholder":
              case "keyHolder": {
                if (completed) {
                  router.push(
                    `/insurance-application/status?applicationID=${applicationID}`,
                  );
                } else {
                  router.push(
                    `/insurance-application/${flowType}/${selectedApplication.started ? "welcome" : "welcome"}/${applicationID}`,
                  );
                }
                break;
              }
            }
          }}
          buttonDisabled={isPending || !selectedApplication}
          buttonText={isPending ? "Loading..." : "Continue"}
        >
          <div className="flex h-full flex-col justify-between">
            <div className="flex flex-col gap-5">
              <h4 className="text-lg leading-6 tracking-tight text-dark-teal-5">
                Please select an application to continue.
              </h4>
              <ApplicationPicker
                selectedApplication={selectedApplication}
                setSelectedApplication={setSelectedApplication}
              />
            </div>
          </div>
        </InsuranceApplicationIllustrationDialog>
      ) : (
        <InsuranceApplicationDialog
          title="AnchorWatch Application"
          disableBackButton
          disableProgressBar
        >
          <div className="flex h-full flex-col p-6">
            <Image
              src={WelcomeLaptop}
              alt="Welcome Laptop"
              priority
              quality={100}
              className="h-full w-auto"
            />
            <div className="flex gap-4">
              <Link
                className={buttonVariants({
                  className: "h-[50px] text-base",
                  variant: "default",
                })}
                href="/auth/signin"
              >
                Log in
              </Link>
            </div>
          </div>
        </InsuranceApplicationDialog>
      )}
    </div>
  );
}
