import { cn } from "@trident/ui";
import { Label } from "@trident/ui/label";
import { RadioGroup, RadioGroupItem } from "@trident/ui/radio-group";
import { ScrollArea } from "@trident/ui/scroll-area";
import { Skeleton } from "@trident/ui/skeleton";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useAuth } from "~/providers/auth2";
import { api, RouterOutputs } from "~/trpc/react";

type Application = RouterOutputs["form"]["accessibleApps"][number];

export function ApplicationPicker({
  selectedApplication,
  setSelectedApplication,
}: {
  selectedApplication: Application | undefined;
  setSelectedApplication: Dispatch<SetStateAction<Application | undefined>>;
}) {
  const utils = api.useUtils();
  const { ready, session, type } = useAuth();
  const { data, isLoading } = api.form.accessibleApps.useQuery(undefined, {
    enabled: !!ready && !!session && type === "user",
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const applications = useMemo(() => {
    if (!data) return [];

    let ownerCount = 0;
    let otherCount = 0;

    return data
      .map((application) => {
        const isOwner = application.owner_email === session?.email;
        return {
          ...application,
          count: isOwner ? ++ownerCount : ++otherCount,
        };
      })
      .sort((a, b) => a.count - b.count);
  }, [data, session?.email]);

  return isLoading ? (
    <div className="flex flex-col gap-2">
      <Skeleton className="h-16 w-full" />
      <Skeleton className="h-16 w-full" />
      <Skeleton className="h-16 w-full" />
    </div>
  ) : !!data && applications.length > 0 ? (
    <ScrollArea className="flex h-72 w-full flex-col items-center justify-center">
      <div className="flex flex-col gap-2">
        {applications.map((application) => (
          <RadioGroup
            value={selectedApplication?.applicationID}
            onValueChange={(v) => {
              const application = data?.find((app) => app.applicationID === v);
              if (application) {
                setSelectedApplication(application);
                if (application.status === "incomplete") {
                  utils.form.sync.prefetch({ applicationID: v });
                  utils.form.statuses.prefetch({ applicationID: v });
                }
              } else {
                console.error("Policy not found");
              }
            }}
            key={application.applicationID}
          >
            <Label
              htmlFor={application.applicationID}
              className="flex min-h-[84px] w-full cursor-pointer items-center rounded-sm border border-grayscale-7 px-5 shadow-card-shadow [&:has([data-state=checked])]:bg-[#D8EAEA]"
            >
              <div className="flex w-full items-center justify-between">
                <div className="grid items-center gap-1.5">
                  <h3
                    className={cn(
                      "text-xl font-medium uppercase leading-6",
                      !selectedApplication
                        ? "text-dark-teal-2"
                        : selectedApplication?.applicationID ===
                            application.applicationID
                          ? "text-dark-teal-2"
                          : "text-grayscale-6",
                    )}
                  >
                    {session?.email === application.owner_email
                      ? `Your Application ${application.count}`
                      : `${!!application.owner_first_name ? application.owner_first_name + "'s" : "Their"} Application ${application.count}`}
                  </h3>
                  <div className="flex gap-2 capitalize">
                    <p
                      className={cn(
                        "text-xl font-medium uppercase leading-6 text-dark-teal-5",
                        !selectedApplication
                          ? "text-dark-teal-5"
                          : selectedApplication?.applicationID ===
                              application.applicationID
                            ? "text-dark-teal-5"
                            : "text-grayscale-6",
                      )}
                    >
                      {
                        {
                          personal: "Personal",
                          enterprise: "Enterprise",
                          stakeholder: "Stakeholder",
                          keyHolder: "Key Holder",
                        }[application.type]
                      }
                    </p>
                    <p
                      className={cn(
                        "text-lg leading-6",
                        !selectedApplication
                          ? "text-sys-orange-1"
                          : selectedApplication?.applicationID ===
                              application.applicationID
                            ? application.status === "requires_action" ||
                              application.status === "incomplete" ||
                              application.status === "pending"
                              ? "text-sys-orange-1"
                              : application.status === "approved"
                                ? "text-sys-green-1"
                                : "text-sys-red-1"
                            : "text-grayscale-6",
                        application.status === "approved" && "text-sys-green-1",
                        application.status === "rejected" && "text-sys-red-1",
                      )}
                    >
                      {application.status === "requires_action"
                        ? "requires action"
                        : application.status}
                    </p>
                  </div>
                </div>
                <div>
                  <RadioGroupItem
                    value={application.applicationID}
                    id={application.applicationID}
                  />
                </div>
              </div>
            </Label>
          </RadioGroup>
        ))}
      </div>
    </ScrollArea>
  ) : (
    <div>No application(s) found</div>
  );
}
